<template>
  <v-container class="toolbox-container" fluid>
    <v-row class="form-block px-3 pt-5">
      <v-col cols="12">
        <v-row
          class="p-3 synapsa-toolbox"
          :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
        >
          <v-col cols="12">
            <v-row v-if="!loading">
              <v-col cols="12" class="pb-0">
                <v-form
                  ref="form"
                  v-model="validCheck"
                  class="pt-5"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12" md="6" class="pb-0">
                      <v-autocomplete
                        v-model="miner"
                        :disabled="checkValid"
                        :items="miners"
                        :rules="minerRules"
                        item-text="name"
                        item-value="id"
                        label="Miner"
                        placeholder="Miner"
                        dense
                        solo
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="12" class="pb-2">
                      <label class="font-roboto-condensed-regular font-size-15">
                        Message
                      </label>
                      <span
                        class="pl-3 font-roboto-condensed-regular font-size-15 cursor-pointer"
                        @click="example = !example"
                      >
                        <v-icon class="font-size-12 mb-1"> fas fa-info </v-icon>
                        Example
                      </span>
                    </v-col>
                  </v-row>
                  <v-row v-if="example">
                    <v-col cols="12">
                      <span class="font-roboto-condensed-regular font-size-15">
                        {{ exampleText }}
                      </span>
                      <v-row>
                        <v-col cols="12" class="pt-4">
                          <v-btn
                            small
                            color="primary"
                            @click="copyToClipboard(exampleText)"
                          >
                            Copy
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <v-textarea
                        v-model="message"
                        :rules="messageRules"
                        placeholder="Message"
                        class="font-roboto-condensed-regular font-size-15"
                        dense
                        solo
                        auto-grow
                        rows="5"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
            <v-row v-if="!loading" class="mx-0 pb-4">
              <v-col cols="12" class="pb-5 pt-0 px-0">
                <v-icon v-if="saving" color="primary">
                  fas fa-spinner fa-spin
                </v-icon>
                <span
                  v-if="saving"
                  class="font-roboto-condensed-light font-size-15 ml-2"
                >
                  Processing...
                </span>
                <v-btn
                  v-if="!saving"
                  color="primary"
                  class="text-capitalize px-5"
                  @click="validate"
                >
                  {{ "Test" }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="loading" class="mt-0 mx-0">
              <v-col cols="12">
                <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
                <span class="font-roboto-condensed-light font-size-15 ml-2"
                  >Loading...</span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-overlay
          v-if="response"
          :dark="$vuetify.theme.dark"
          class="synapsa-overlay"
        >
          <v-card>
            <v-card-title class="py-1 pr-2 font-ubuntu-bold text-uppercase">
              Saved!
            </v-card-title>
            <v-list width="350" class="pt-0 pb-3">
              <v-list-item>
                <v-list-item-content class="pb-0">
                  {{
                    minerSource === 4
                      ? "Event ID: " + response
                      : "SysLog ID: " + response
                  }}
                  <v-list-item-subtitle class="text-left mt-3">
                    <v-btn
                      small
                      color="primary"
                      class="text-capitalize"
                      @click="response = false"
                    >
                      Close
                    </v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from "moment";
import { Errors } from "@/utils/error";
import { getMinersList } from "@/api/settings/miner";
import { getMiner } from "@/api/settings/miner";
//import { threatSimulatorHelp } from "@/api/toolbox/threatSimulator";
import { threatSimulatorPost } from "@/api/toolbox/threatSimulator";
export default {
  name: "threatSimulator",
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,
      perspectiveValid: null,
      checked: null,
      valid: true,
      validCheck: true,
      saving: false,
      data: null,
      miner: null,
      minerSource: null,
      date: null,
      timestamp: null,
      jsonDate: null,
      response: false,
      minerRules: [(v) => !!v || "Miner is required"],
      miners: [],
      message: null,
      messageRules: [(v) => !!v || "Message is required"],
      example: 0,
      start: null,
      exampleText: null,
      ip: null,
    };
  },
  watch: {
    miner: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.dateFunction();
        if (newValue != null) {
          getMiner(newValue)
            .then((response) => {
              this.minerSource = response.data.source;
              if (response.data.source === 1) {
                this.exampleText =
                  "CEF:0|Flowmon Networks|Flowmon ADS Enterprisee|8.00.00|COUNTRY|Country reputation|4|src=" +
                  this.generateIp() +
                  " start=" +
                  this.date +
                  " msg=Unusual communication to the country %Release2.0.0 (device: upload: %Release2.1.0, download: %3, upload/download ratio: %4";
              } else {
                this.exampleText = {
                  id: this.timestamp,
                  time: this.jsonDate,
                  timeLast: "2021-04-19 08:29:20",
                  source: {
                    ip: this.generateIp(),
                    resolved: this.generateIp() + ".bc.googleusercontent.com",
                    country: "BR",
                    blacklisted: 0,
                  },
                  techniques:
                    "Exfiltration: Exfiltration Over Alternative Protocol",
                  type: "ICMPANOM",
                  subtype: "LargePayload",
                  nfSource: {
                    id: 2,
                    name: "Default",
                    virtual: 0,
                    location: "localhost",
                  },
                  batch: null,
                  flowStamp: "2021-04-19 08:26:32",
                  detail:
                    "A large payload of ICMP packets has been detected. Payload: 60.004 B, number of packets: 13155, ICMP type: 0, median of the payload in the network: 60B.",
                  targets: [
                    {
                      ip: this.generateIp(),
                      resolved: "",
                      country: "LAN",
                      blacklisted: 0,
                    },
                  ],
                  perspectives: [
                    {
                      id: 6,
                      name: "perspective_6",
                      priority: 3,
                    },
                    {
                      id: 7,
                      name: "perspective_7",
                      priority: 3,
                    },
                    {
                      id: 8,
                      name: "perspective_8",
                      priority: 5,
                    },
                  ],
                  priority: 5,
                  interest: 789300,
                  comments: [],
                  attributes: {
                    ports: [0],
                    median: 60,
                    average: 60.003999999999998,
                    bytesIn: 0,
                    flowsIn: 0,
                    timeEnd: 1618813652750,
                    bytesOut: 789300,
                    flowsOut: 1,
                    iCMPType: 0,
                    packetsIn: 0,
                    protocols: [1],
                    timeStart: 1618813592015,
                    packetsOut: 13155,
                    durationMax: 60735,
                    durationMin: 60735,
                    targetCount: 0,
                    flowsSingleIn: 0,
                    flowsSingleOut: 1,
                  },
                };
              }
            })
            .catch((e) => {
              Errors.show(e);
            });
        }
      },
    },
  },
  mounted() {
    this.loadMiners();
    this.dateFunction();
    this.generateIp();
  },
  methods: {
    loadMiners() {
      this.loading = true;
      getMinersList()
        .then((response) => {
          this.miners = response.data;
          this.loading = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    copyToClipboard(text) {
      var value;
      if (this.minerSource === 4) {
        value = JSON.stringify(text);
      } else {
        value = text;
      }
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = value;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.message = value;
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.test();
      }
    },
    test() {
      this.saving = true;
      var data;
      if (this.minerSource === 4) {
        data = JSON.parse(this.message);
      } else {
        data = {
          message: this.message,
        };
      }
      threatSimulatorPost(data, this.miner)
        .then((response) => {
          if (response.empty == false) {
            this.response = response.systemevent_id;
          } else {
            var message = response.message;

            this.$toast.open({
              message: message,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
          }
          this.saving = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    dateFunction: function () {
      var currentDate = new Date();
      this.timestamp = currentDate.getTime();
      this.date = moment(String(currentDate)).format("ddd MMM DD YYYY HH:mm");
      this.jsonDate = moment(String(currentDate)).format("YYYY-MM-DD HH:mm:ss");
    },
    generateIp() {
      this.ip =
        Math.floor(Math.random() * 255) +
        1 +
        "." +
        Math.floor(Math.random() * 255) +
        "." +
        Math.floor(Math.random() * 255) +
        "." +
        Math.floor(Math.random() * 255);
      return this.ip;
    },
  },
};
</script>
<style lang="scss" scoped>
.synapsa-overlay .v-card {
  background: black;
}
</style>
